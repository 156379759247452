<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Lokasi</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bold" for="id_cat_maintenance"
                    >Category Maintenance
                  </label>
                  <select
                    v-model="form.id_cat_maintenance"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_cat_maintenance = ''"
                    :class="{
                      'is-valid': form.id_cat_maintenance != '',
                      'is-invalid':
                        formValidate.id_cat_maintenance ||
                        form.id_cat_maintenance == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="kategori in listKategori"
                      :key="kategori.id_cat_maintenance"
                      :value="kategori.id_cat_maintenance"
                    >
                      {{ kategori.nama_cat_maintenance }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bold" for="item_maintenance"
                    >Item Maintenance
                  </label>
                  <input
                    v-model="form.item_maintenance"
                    type="email"
                    class="form-control"
                    placeholder="Input Item Maintenance"
                    required
                    @input="formValidate.item_maintenance = ''"
                    :class="{
                      'is-valid': form.item_maintenance != '',
                      'is-invalid':
                        formValidate.item_maintenance ||
                        form.item_maintenance == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bold" for="standard_maintenance"
                    >Standard Maintenance
                  </label>
                  <input
                    v-model="form.standard_maintenance"
                    type="tel"
                    class="form-control"
                    placeholder="Input Standard Maintenance"
                    required
                    @input="formValidate.standard_maintenance = ''"
                    :class="{
                      'is-valid': form.standard_maintenance != '',
                      'is-invalid':
                        formValidate.standard_maintenance ||
                        form.standard_maintenance == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
                <button @click="showModal" class="btn btn-warning ml-1">
                  Tambah Kategori Baru
                </button>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Tambah Category Maintenance</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="bold" for="nama_cat_maintenance"
                >Category Maintenance
              </label>
              <input
                v-model="kategori.nama_cat_maintenance"
                type="tel"
                class="form-control"
                placeholder="Input Category Maintenance"
                required
                @input="formValidate.nama_cat_maintenance = ''"
                :class="{
                  'is-valid': kategori.nama_cat_maintenance != '',
                  'is-invalid':
                    formValidate.nama_cat_maintenance ||
                    kategori.nama_cat_maintenance == '',
                }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <b-alert v-if="statusCategory" variant="success" show fade>
              <strong>Success!</strong>
              <br />
              <span>{{ kategoriAction }} Category Maintenance Sukses</span>
            </b-alert>
          </div>
          <div class="col-md-12">
            <div style="max-height: 50vh; overflow-y: scroll">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Category Maintenance</th>
                    <th style="width: 100px"><i class="fa fa-cogs"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="kategori in listKategori"
                    :key="kategori.id_cat_maintenance"
                  >
                    <td>{{ kategori.nama_cat_maintenance }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-icon btn-warning m-r-5"
                        v-on:click="
                          editKategori(
                            kategori.id_cat_maintenance,
                            kategori.nama_cat_maintenance
                          )
                        "
                      >
                        <i class="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        class="btn btn-icon btn-danger"
                        v-on:click="confirmDelete(kategori.id_cat_maintenance)"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button @click="closeModal" class="btn btn-info" variant="primary">
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postCatergory"
          variant="primary"
          :disabled="loadingCategory"
        >
          <i v-if="loadingCategory" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_maintenance: "",
        id_cat_maintenance: "",
        item_maintenance: "",
        standard_maintenance: "",
      },
      kategori: {
        id_cat_maintenance: "",
        nama_cat_maintenance: "",
      },
      listKategori: [],
      formValidate: [],
      loading: false,
      loadingCategory: false,
      modalVisible: false,
      conditionMet: false,
      statusCategory: false,
      kategoriAction: "Create",
    };
  },
  mounted() {
    this.getCatergoryMaintenance();
  },
  methods: {
    getCatergoryMaintenance() {
      axios
        .get(this.$url+"/v2/kategori_maintenance/")
        .then((response) => {
          this.listKategori = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(`${this.$url}/v2/maintenance/create/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postCatergory() {
      this.loadingCategory = true;
      const formData = new FormData();
      for (let key in this.kategori) {
        formData.append(key, this.kategori[key]);
      }
      axios
        .post(`${this.$url}/v2/kategori_maintenance/${this.kategoriAction}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingCategory = false;
            this.formValidate = response.data.message;
          } else {
            this.loadingCategory = false;
            this.statusCategory = true;
            setTimeout(() => {
              this.statusCategory = false;
              this.kategori.nama_cat_maintenance = "";
              this.closeModal();
              this.getCatergoryMaintenance();
            }, 1000);
          }
        })
        .catch((error) => {
          this.loadingCategory = false;
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
      this.kategoriAction = "Create";
      this.kategori.id_cat_maintenance = "";
      this.kategori.nama_cat_maintenance = "";
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get(`${this.$url}/v2/kategori_maintenance/delete/${id}`)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.getCatergoryMaintenance();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal",
              text: error.response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.getCatergoryMaintenance();
            });
            console.log(error.response.data.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    editKategori(id, name) {
      this.kategoriAction = "Update";
      this.kategori.id_cat_maintenance = id;
      this.kategori.nama_cat_maintenance = name;
    },
  },
};
</script>